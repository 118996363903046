<template>
  <div class="proxy-container">
    <div class="proxy-item" v-for="proxy in proxies" :key="proxy.id">
      <div class="logo"><img :src="proxy.logo" alt="proxy logo" /></div>
      <div class="details">
        <div class="url">
          <i class="fas fa-link"></i>
          <a :href="proxy.url" target="_blank">{{ proxy.url }}</a>
        </div>
        <div class="actions">
          <button @click="copy(proxy.url)">复制</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Proxy',
  data() {
    return {
      proxies: [
        { id: 1, logo: require('@/assets/img/proxy/douyu.png'), url: 'https://isus.cc/php/douyu.php?id=' },
        { id: 2, logo: require('@/assets/img/proxy/huya.png'), url: 'https://isus.cc/php/huya.php?id=' },
        { id: 3, logo: require('@/assets/img/proxy/yy.png'), url: 'https://isus.cc/php/yy.php?id=' },
        { id: 4, logo: require('@/assets/img/proxy/bilibili.png'), url: 'http://synology.isus.cc:919/bilibili.php?id=' },
        { id: 5, logo: require('@/assets/img/proxy/twitch.png'), url: 'https://isus.cc/php/twitch.php?id=' },
        { id: 6, logo: require('@/assets/img/proxy/tikred.png'), url: 'https://isus.cc/php/tikred.php?id=' },
      ]
    }
  },
  methods: {
    copy(url) {
      // 调用全局的复制服务
      this.$clipboard(url).then(() => {
        this.$message({
          message: '已复制到剪贴板',
          type: 'success',
          duration: 1000
        });
      })
        .catch(() => {
          this.$message.error('复制失败');
        });
    }
  }
}
</script>

<style scoped lang="scss">
.proxy-container {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 20px;
  border-radius: 10px;

  .proxy-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    border: 2px solid #87CEEB;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);

    .logo {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #87CEEB;
      margin-right: 20px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;

      .url {
        display: flex;
        align-items: center;
        color: #555;
        font-size: 0.9em;
        word-break: break-all;
        background-color: rgba(135, 206, 235, 0.1);
        padding: 5px 10px;
        border-radius: 5px;

        i {
          margin-right: 5px;
          color: #87CEEB;
        }

        a {
          color: #555;
          text-decoration: none;

          &:hover {
            color: #87CEEB;
            text-decoration: underline;
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: row;

        button {
          margin-left: 10px;
          background-color: #87CEEB;
          border: none;
          color: white;
          padding: 8px 12px;
          cursor: pointer;
          border-radius: 5px;
          transition: background-color 0.3s;

          &:hover {
            background-color: #6BB3DA;
          }
        }
      }
    }
  }

  /* 移动端样式（小于 767px） */
  @media screen and (max-width: 767px) {
    .proxy-item {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .logo {
        margin-bottom: 10px;
        margin-right: 0;
      }

      .details {
        flex-direction: column;
        align-items: center;
        width: 100%;

        .actions {
          flex-direction: column;
          width: 100%;

          button {
            width: 100%;
            margin: 5px 0;
          }
        }
      }
    }
  }
}
</style>
